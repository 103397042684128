/* styles.css */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden !important;
  overflow-y: auto;
  position: relative;
  min-height: 100vh;
  margin: 0;
  font-size: 12px;
  background-attachment: fixed;  
}

/* Créer un élément pour le fond */
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./images/background.png");
  background-size: cover;
  background-position: center;
  z-index: -1;
  height: 100vh;
}

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

/* Header */
.header {
  background: linear-gradient(45deg, #8b4d25, #b35829, #cc6d3d);
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.3);
  padding: 20px 0;
  min-height: 120px; /* Augmente la hauteur minimale du header */
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px;
}

.header-logo {
  position: absolute;
  left: 20px; /* Distance du bord gauche */
  width: 100px;
  height: auto; /* Maintient les proportions */
  margin-top: 10px; 
}

.header-title {
  font-size: 40px; /* Augmenter la taille du texte */
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 4px; /* Espacement des lettres */
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5); /* Ombre portée pour le texte */
  transition: all 0.3s ease-in-out; /* Transition douce pour l'effet hover */
  position: relative;
}

/* Tableau de musique */
.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 150px;
  width: 100%;
  justify-content: center;   
}

.music-table {
  width: 90%;
  margin: 0 auto;
  border: 1px solid #9f4d29;
  border-radius: 15px;
  box-shadow: 8px 8px 25px rgba(0, 0, 0, 0.6);
  background-color: white;
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
}

.table-header {
  text-align: center !important;
  font-size: 16px;
  font-weight: 700;
  color: white;
  background-color: #9f4d29;
  border-bottom: 2px solid #7d3c1e;
  padding: 12px;
}

.music-table th:first-child {
  border-top-left-radius: 15px;
}

.music-table th:last-child {
  border-top-right-radius: 15px;
}

.music-table th,
.music-table td {
  padding: 12px;
  text-align: left;
  font-size: 14px;
  border: 1px solid #d4a16a;
}

.music-table tbody tr:nth-child(even) {
  background-color: #e0b18a;
}

.music-table tbody tr:nth-child(odd) {
  background-color: #fdf0d5;
}

.music-table tbody tr:hover {
  background-color: #d99b6a;
  cursor: pointer;
}

.music-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 15px;
}

.music-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
}

.music-table td:first-child {
  border-left: none;
}

.music-table td:last-child {
  border-right: none;
}

.music-table tbody tr td:first-child,
.music-table tbody tr td:last-child {
  border-left: 1px solid #d4a16a;
  border-right: 1px solid #d4a16a;
}

.table-image {
  display: block;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  object-fit: cover;
}

.center {
  text-align: center !important;
}

.centered-button button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.play-button,
.download-button {
  border: none;
  background: linear-gradient(145deg, #ff7f50, #ff6347);
  color: white;
  cursor: pointer;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 0 4px;
}

.play-button:hover,
.download-button:hover {
  background: linear-gradient(145deg, #ff6347, #ff7f50);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.play-button:focus,
.download-button:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(182, 98, 1, 0.7);
}

.play-button:disabled,
.download-button:disabled {
  background: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
  box-shadow: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 1s infinite;
}

.play-button.pulse {
  animation: pulse 1s infinite;
}

/* Styles pour la pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 100px;
  flex-wrap: wrap;
}

.pagination-button {
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 50px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.pagination-button:hover {
  background: linear-gradient(135deg, #a05122, #cc6d3d);
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.pagination-button:disabled {
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
  box-shadow: none;
}

/* Style pour la page active */
.pagination-button.current-page {
  background-color: #d64f3e !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  font-weight: bold;
  color: yellow;
}

.pagination-button.current-page:hover {
  background-color: #e66a56;
  transform: scale(1.1);
  color: white;
}

.pagination-button:not(.current-page):not(.first):not(.previous):not(.next):not(
    .last
  ) {
  background: linear-gradient(135deg, #cc6d3d, #a05122);
}

.pagination-button.first,
.pagination-button.previous,
.pagination-button.next,
.pagination-button.last {
  background: linear-gradient(135deg, #cc6d3d, #a05122);
}

.pagination-button:not(.current-page):not(.first):not(.previous):not(.next):not(
    .last
  ):hover {
  background: linear-gradient(135deg, #a05122, #cc6d3d);
}

/* Footer */
.footer {
  background: linear-gradient(45deg, #022e51, #1e3c56, #3a5b75);
  color: #fff;
  padding: 20px 0;
  width: 100%;
  text-align: center;
  z-index: 100;
  position: absolute;
  margin-top: -150px;
  bottom: 0;
  height: 200px;
}

.footer p {
  font-size: 16px;
  margin-bottom: 10px;
}

.footer-text.main-paragraph {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.footer-text {
  font-size: 14px;
  color: #fff;
}

.terms-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.terms-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.footer-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.youtube-link {
  text-decoration: none;
}

.youtube-button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.youtube-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.youtube-button:hover {
  background-color: #cc0000;
  transform: scale(1.05);
}

/* Styles de la modale */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #333;
  padding: 20px;
  width: 400px;
  max-width: 80%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  color: #fff;
}

.modal-header {
  text-align: center;
}

.modal-body {
  padding: 20px;
  text-align: justify;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modal-button:hover {
  background-color: #45a049;
}

/* Conteneur de la barre de recherche */
.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

.search-container input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  font-size: 18px;
  border-radius: 40px;
  border: 2px solid #a05122;
  background-color: #cc6d3d;
  color: #a05122;
  transition: all 0.3s ease;
  outline: none;
}

.search-container input[type="text"]:focus {
  border-color: #a05122;
  background-color: #d4a16a;
}

.search-container input[type="text"]::placeholder {
  color: #fff;
  font-style: italic;
}

/* Media queries for mobile responsiveness */
@media screen and (max-width: 768px) {
  body {
    margin: 0;
    padding: 0;
  }

  .header-content {
    padding: 5px;
  }

  .header {
    min-height: 100px; /* Réduit la hauteur minimale du header sur mobile */
    padding: 10px 0;
  }

  .header-logo {
    width: 50px; /* Réduit la taille du logo sur mobile */
  }

  .header-title {
    font-size: 28px; /* Réduit la taille du texte sur mobile */
  }

  /* Reduce footer size */
  .footer {
    padding: 10px 0;
    height: 100px;    
  }

  .footer-content {
    font-size: 14px;
  }

  .footer p:nth-child(2) {
    display: none;
  }

  /* Réduire la taille des boutons de pagination pour mobile */
  .pagination-button {
    padding: 10px 5px;
    font-size: 11px;
  }

  .pagination-button.current-page {
    background-color: #d9534f !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    color: yellow !important;
  }

  .pagination {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 10px 0;   
  }

  .pagination-button:first-child,
  .pagination-button:last-child {
    margin-left: 4px;
    margin-right: 4px;
  }

  .pagination-ellipsis {
    font-size: 10px;
    color: #a05122;
  }

  .style-column,
  .duration-column,
  .about-column {
    display: none;
  }

  .music-table .table-header.style-column,
  .music-table .table-header.duration-column,
  .music-table .table-header.about-column {
    display: none;
  }

  .image-column,
  .title-column,
  .play-column,
  .download-column {
    width: 25%;
  }

  .table-image {
    max-width: 100%;
    height: auto;
  }

  .play-button,
  .download-button {
    padding: 2px 6px;
    font-size: 8px;
    border-radius: 20px;
    margin: 0 2px;
  }
  .table-header {
    font-size: 9px;
    padding: 5px;
  }
  .music-table {
    font-size: 8px;
    table-layout: auto;
    width: 100%;    
  }

  .music-table th,
  .music-table td {
    padding: 5px;
    word-wrap: break-word;
  }

  .table-container {
    padding: 0 10px;
  }

  .youtube-button {
    font-size: 10px;
  }

  .youtube-logo {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }

  .terms-button {
    font-size: 10px;
  }
}
